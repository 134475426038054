import { PairingRoundListInGlobalRound } from '../components/Pairing/Round';

function GlobalPairingRoundRoundsRoute() {
  return <PairingRoundListInGlobalRound />;
}

export const Component = GlobalPairingRoundRoundsRoute;

export function clientLoader() {
  return null;
}
